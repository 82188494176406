// Code
// overwrite theme variables
@font-face {
  font-family: "icomoon";
  src: url("../assets/fonts/icomoon.eot");
  src: url("../assets/fonts/icomoon.eot") format("embedded-opentype"),
    url("../assets/fonts/icomoon.ttf") format("truetype"),
    url("../assets/fonts/icomoon.woff") format("woff"),
    url("../assets/fonts/icomoon.svg") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: 500;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-agriculture:before {
  content: "\e91e";
}
.icon-leaf:before {
  content: "\e91f";
}
.icon-voicemail:before {
  content: "\e907";
}
.icon-mail:before {
  content: "\e908";
}
.icon-alert-triangle:before {
  content: "\e909";
}
.icon-users:before {
  content: "\e90a";
}
.icon-message-square:before {
  content: "\e90b";
}
.icon-message-circle:before {
  content: "\e90c";
}
.icon-credit-card:before {
  content: "\e90d";
}
.icon-home:before {
  content: "\e90e";
}
.icon-bar-chart-2:before {
  content: "\e90f";
}
.icon-pie-chart1:before {
  content: "\e910";
}
.icon-camera:before {
  content: "\e911";
}
.icon-calendar:before {
  content: "\e912";
}
.icon-magnify:before {
  content: "\e913";
}
.icon-tranding:before {
  content: "\e914";
}
.icon-ping:before {
  content: "\e915";
}
.icon-plus-lg:before {
  content: "\e916";
}
.icon-bell:before {
  content: "\e917";
}
.icon-help:before {
  content: "\e918";
}
.icon-settings:before {
  content: "\e919";
}
.icon-user:before {
  content: "\e91a";
}
.icon-file:before {
  content: "\e91b";
}
.icon-security:before {
  content: "\e91c";
}
.icon-truck:before {
  content: "\e91d";
}
.icon-chat:before {
  content: "\e900";
}
.icon-bubbles:before {
  content: "\e900";
}
.icon-talk:before {
  content: "\e900";
}
.icon-message:before {
  content: "\e900";
}
.icon-conversation:before {
  content: "\e900";
}
.icon-wallet:before {
  content: "\e901";
}
.icon-money:before {
  content: "\e901";
}
.icon-drivers-license-o:before {
  content: "\e902";
}
.icon-id-card-o:before {
  content: "\e902";
}
.icon-comments-o:before {
  content: "\e903";
}
.icon-comment-o:before {
  content: "\e904";
}
.icon-area-chart:before {
  content: "\e905";
}
.icon-pie-chart:before {
  content: "\e906";
}
.icon-cash:before {
  content: "\e93b";
}
.icon-lock1:before {
  content: "\e98f";
}
.icon-plant:before {
  content: "\e9a4";
}
.icon-truck1:before {
  content: "\e9b0";
}


$theme-colors: (
  "primary": #92c31a,
  "secondary": #217dba,
  "info": #636b70,
  "infoLight": #a5a6a4,
  "secondaryDark": #3f3d56,
  "acceptDark": #3f3d56,
  "primaryLight": #effbcd,
  "font-info": #797878 // "success": red,
  // "danger": red,
  // "warning": red,
  // "light": red,
  // "dark": red,,,
);

$font-size-base: 16;
body {
  font-family: "Raleway", sans-serif !important;
  font-feature-settings: 'lnum' 1;
}

// ElementsCore
@import "./components/atoms/ElementsCore.scss";

// Shadow
@import "./components/atoms/Shadow.scss";

// Elements
@import "./components/atoms/Elements.scss";

// Bootstrap
@import "~bootstrap/scss/bootstrap";
