.rs-table-row {
  overflow: hidden;
  position: absolute;
  height: 36px;
  width: 100%;
  top: 0;
  border-bottom: 1px solid #a9a8a8;
}

.rs-table-body-wheel-area .rs-table-row {
  padding-top: 10px;
}

.rs-picker-menu {
  z-index: 99999999999;
}

.MuiTab-root {
  text-transform: none !important;
}
.MuiTabs-flexContainer {
  display: flex;
  justify-content: space-between;
}
.PrivateTabIndicator-root-11 {
  height: 3px !important;
}

span.MuiTab-wrapper {
  font-weight: bold;
}

.group-container-radios {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.rating-parent-log.react-stars {
  width: 100px !important;
  display: flex;
  justify-content: space-evenly;
  left: -3px;
    top: -89px;
}

.rating-postulate.react-stars {
  width: 100px !important;
  display: flex;
  justify-content: space-evenly;
}

.rating-contact-log.react-stars {
  width: 100px !important;
  display: flex;
  justify-content: space-evenly;
  left: -3px;
    top: -89px;
}

span.rs-timeline-item-tail {
  background: #92c31a;
}

span.rs-timeline-item-dot:before {
  background: #92c31a !important;
}

._loading_overlay_wrapper {
  height: 100vh !important;
}

.cursor-pointer {
  cursor: pointer !important;
}
.sidebar,
.main-panel {
  overflow: inherit !important;
}

._loading_overlay_overlay {
  background: rgba(255, 255, 255, 0.232) !important;
  z-index: 9999 !important;
}

.content-page {
  min-height: 80vh;
  display: flex;
  justify-content: center !important;
  align-items: center !important;
}
input.log-radio {
  margin-top: 5px;
  margin-right: 5px;
}
// ui elements

.modal-dialog-map .modal-dialog {
  max-width: 80% !important;
}
.modal-exit.modal.show {
  pointer-events: all !important;
}

// tomardor tables
.rs-table-row.rs-table-row-header {
  background: #9dc537 !important;
  color: white !important;
  font-weight: bold;
  border-radius: 20px 20px 0px 0px;
  align-items: baseline;
}

.rs-table-cell-header .rs-table-cell-content {
  font-size: 14px !important;
}

.rs-table-row.rs-table-row-header .rs-table-cell-content {
  font-weight: bold;
}

.rs-table-cell-content {
  padding: 8px 6px;
}
.rs-table-row-header .rs-table-cell-content {
  height: 40px !important;
}

.rs-table-row.rs-table-row-header .rs-table-cell-group .rs-table-cell {
  background: #9dc537 !important;
  color: white !important;
}

svg.icon-clock-time {
  position: absolute;
  left: 10px;
  fill: #92c31a;
  z-index: 99;
}

.btn-list-bottom {
  height: 70px;
}

.circle-icon {
  background: #f6f6f5;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;

  span {
    font-size: 25px;
  }
}

.circle-icon-list {
  background: #f6f6f5;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  margin-right: 8px;
}

.card-header {
  background: white !important;
}

.fade.in {
  opacity: 1 !important;
  pointer-events: unset;
}

.rs-calendar-table-cell-is-today .rs-calendar-table-cell-content,
.rs-picker-toolbar-right-btn-ok {
  border-color: #92c31a !important;
  background-color: #92c31a !important;
  color: white;
}

.rs-picker-toolbar-option {
  color: #92c31a !important;
}

.rs-calendar-time-dropdown-cell-active.rs-calendar-time-dropdown-cell,
.rs-calendar-time-dropdown-cell-active.rs-calendar-time-dropdown-cell:hover {
  background-color: #92c31a !important;
}

.rs-picker-default:not(.rs-picker-disabled) .rs-btn:hover,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:hover,
.rs-picker-default:not(.rs-picker-disabled) .rs-btn.active,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle.active {
  border-color: #92c31a;
}

.rs-picker-has-value .rs-btn .rs-picker-toggle-value,
.rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
  color: #636b70;
}

.rs-picker-select-menu-item
  rs-picker-select-menu-item-active
  rs-picker-select-menu-item-focus {
  color: #92c31a !important;
}

.rs-calendar {
  font-family: arial !important;
}

.rs-picker-select-menu-item.rs-picker-select-menu-item-active,
.rs-picker-select-menu-item.rs-picker-select-menu-item-active:hover {
  color: #92c31a !important;
}

.rs-calendar-show-time-dropdown .rs-calendar-header-title-time {
  color: #92c31a !important;
}

.rs-picker-value-count {
  background: #92c31a !important;
}

.rs-checkbox-checked .rs-checkbox-wrapper .rs-checkbox-inner::before,
.rs-checkbox-indeterminate .rs-checkbox-wrapper .rs-checkbox-inner::before {
  color: #92c31a !important;
}
.rs-picker-default .rs-picker-toggle {
  border: none;
}

.card-log-design {
  border-radius: 15px;
}

.form-check-input:checked[type="radio"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}

.form-check-input:checked[type="checkbox"] {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3E%3C/svg%3E");
}

.form-check-input:checked {
  background-color: #92c31a;
  border-color: #92c31a;
}

.form-check-input[type="radio"] {
  margin-top: 0rem;
  border-radius: 50%;
}
.form-check-input[type="radio"]:focus {
  box-shadow: 0 0 0 0.2rem #92c31a40;
}

.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
}

.log-radio {
  border: none;
}

.map-container {
  width: 100%;
  height: 350px;
  max-height: 100vh;
}

.NavBarElementsParent {
  display: flex;
  flex-wrap: wrap;
  height: 40px;
}
.facebook-button-class {
  background: #3e5c94;
  width: 100%;
  height: 42px;
  border: 0px;
  border-radius: 20px !important;
  margin-top: 5px !important;
  color: white;
  padding: 10px;
  font-size: 16px !important;
  i {
    margin-right: 10px;
  }
}

.NavBar {
  .router-name-display {
    position: relative;
    top: 42px;
    font-weight: bold !important;
    color: black !important;
  }

  .dropdown-toggle::after {
    border-top: none !important;
    border-top: none !important;
    border-right: none !important;
    border-left: none !important;
  }
}

.divider {
  /* minor cosmetics */
  display: table;
  font-size: 16px;
  text-align: center;
  width: 100%; /* divider width */
  margin: 0px auto; /* spacing above/below */
}

.divider span {
  display: table-cell;
  position: relative;
  height: 5px;
}

.divider span:first-child,
.divider span:last-child {
  width: 50%;
  top: 13px; /* adjust vertical align */
  -moz-background-size: 100% 2px; /* line width */
  background-size: 100% 1px; /* line width */
  background-position: 0 0, 0 100%;
  background-repeat: no-repeat;
}

.divider span:first-child {
  /* color changes in here */
  background-image: -webkit-gradient(
    linear,
    0 0,
    0 100%,
    from(#636b70),
    to(#636b70)
  );
  background-image: -webkit-linear-gradient(180deg, #636b70, #636b70);
  background-image: -moz-linear-gradient(180deg, #636b70, #636b70);
  background-image: -o-linear-gradient(180deg, #636b70, #636b70);
  background-image: linear-gradient(90deg, #636b70, #636b70);
}

.divider span:nth-child(2) {
  color: #636b70;
  padding: 2px 5px;
  width: auto;
  white-space: nowrap;
}

.divider span:last-child {
  /* color changes in here */
  background-image: -webkit-gradient(
    linear,
    0 0,
    0 100%,
    from(#636b70),
    to(#636b70)
  );
  background-image: -webkit-linear-gradient(180deg, #636b70, #636b70);
  background-image: -moz-linear-gradient(180deg, #636b70, #636b70);
  background-image: -o-linear-gradient(180deg, #636b70, #636b70);
  background-image: linear-gradient(90deg, #636b70, #636b70);
}

.number-font.rs-calendar {
  font-family: "arial" !important;
  font-size: 1rem !important;
  color: blue input {
    font-family: "arial" !important;
    font-size: 1rem !important;
    color: blue;
  }
}

.number-font {
  font-family: "arial" !important;
  div {
    display: inline-block !important;
  }
}

.check-inline {
  display: flex;
  align-items: cneter;
  align-content: center;
  align-items: center;

  .form-check-input {
    position: relative;
    top: 2px;
    margin-right: 6px;
  }
}

// map components
.marker {
  background: #ffffffbd;
  border-radius: 10px;
  padding: 6px;
  opacity: 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column-reverse;
}

.marker:hover {
  opacity: 1;
}

.container-icon:hover {
  .marker {
    opacity: 1;
  }
}

h1.marker-title {
  font-size: 16px;
  font-weight: bold;
}
.property_title{
  font-size: 14px;
  font-weight: bold;
}
.property_subTitle{
  font-size: 12px;
  margin: 0;
}