.wrapper {
  position: relative;
  top: 0;
  height: 100%;
}

.no-fade {
  opacity: 1 !important;
}

// map
#map_center {
  position: absolute;
  z-index: 99;
  height: 40px;
  width: 40px;
  top: 50%;
  left: 50%;
  margin-left: -21px;
  margin-top: -41px;
}
// dashboard

#map {
  position: relative;
  width: 100%;
  height: 100%;
}
