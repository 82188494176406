.log-select {
  background: #f6f6f4 url("../../../assets/svg/down-arrow-svgrepo-com.svg")
    right 0.75rem center/10px 10px no-repeat !important;
  border: none !important;
  height: 50px !important;
  border-radius: 2px !important;
  &:focus {
    background: #ffffff url("../../../assets/svg/down-arrow-svgrepo-com.svg")
    right 0.75rem center/10px 10px no-repeat !important;
  }
}

.color-option-black {
  color: #212121 !important;
}
